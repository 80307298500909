import React, { useEffect } from 'react'
import './styles.css'

import logo from '../../assets/captura/logo.png'
import loading from '../../assets/captura/loading.png'
import wpp from '../../assets/obrigado/wpp.png'

export default function ObrigadoNew() {

    useEffect(() => {
        document.title = 'Obrigado por se cadastrar !'        
    }, [])

    return (
        <>
            <section style={{ background: "url(https://i.ibb.co/6BLkNwN/bgka.png) no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} className="bg1y d-flex flex-column justify-content-center align-items-center obg__page px-4">

                {/* <img src={"https://instagram.ffor8-1.fna.fbcdn.net/v/t51.2885-19/s320x320/164658646_463644224836802_123962237405712247_n.jpg?tp=1&_nc_ht=instagram.ffor8-1.fna.fbcdn.net&_nc_ohc=wRx6_9cLNLUAX_3j4nO&edm=ABfd0MgAAAAA&ccb=7-4&oh=02d981e63df20c05639f12569be19e4f&oe=609F793B&_nc_sid=7bff83"} className="img-fluid links__giulia" alt="Manuela"></img> */}
 
                <img src={logo} className="img-fluid mt-4 mb-2" alt="logo"></img>

                <p className="font22 wg7 bolder white font-24 mt-2">
                    90% Concluído!
                </p>

                <img src={loading} className="img-fluid mb-3" alt="logo"></img>

                <p className="text-center bolder white font-24 gratidao__text mx-4">
                    Gratidão por se inscrever para estar comigo nesses encontros maravilhosos!
                </p>

                <div className="col-lg-6 d-flex flex-column justify-content-center  text-center px-3 ">

                    <p className="a__text20 thin white ">
                        Agora só falta você <span className="wg7">entrar no grupo de avisos do WhatsApp</span> para não perder nenhum aviso sobre as aulas!.
                    </p> 
                    <p className="a__text20 thin white bolder" style={{ margin: '0' }}>
                        É muito importante que você esteja lá !
                    </p>

                </div>

                <div className="col-lg-5 mt-3 d-flex flex-column justify-content-center align-items-center ">

                    <a href="https://meugrupo.vip/c/2775" className=" btn btn__cta__obg btn-success mt-4 mb-4">
                        <img src={wpp} className="img-fluid wpp__icon mx-2" alt="logo"></img>
                        <span className="mt-5">
                            ENTRAR NO GRUPO AGORA
                        </span>
                    </a>
                </div>
            </section>
        </>
    )
}