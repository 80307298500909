import React, { useEffect } from 'react'
import './styles.css'

import logo from '../../assets/captura/logo.png'
import separator from '../../assets/captura/separator.png'

import a1 from '../../assets/captura/a1.png'
import a2 from '../../assets/captura/a2.png'
import a3 from '../../assets/captura/a3.png'
import a4 from '../../assets/captura/a4.png'
import a5 from '../../assets/captura/a5.png'
import a6 from '../../assets/captura/a6.png'
import a7 from '../../assets/captura/a7.png'
import a8 from '../../assets/captura/a8.png'
import a9 from '../../assets/captura/a9.png'

import b1 from '../../assets/captura/b1.png'
import b2 from '../../assets/captura/b2.png'
import b3 from '../../assets/captura/b3.png'
import b4 from '../../assets/captura/b4.png'

import c1 from '../../assets/captura/c1.png'
import c2 from '../../assets/captura/c2.png'
import c3 from '../../assets/captura/c3.png'
import c4 from '../../assets/captura/c4.png'

import circle from '../../assets/captura/circle.png'

import giu1 from '../../assets/captura/giu1.png'
import giu2 from '../../assets/captura/giu2.png'

const pageData = {
    mainBackgroundColor: "url(https://i.ibb.co/6BLkNwN/bgka.png) no-repeat",
    mainColor: "#710A65",
    Headline: "Realize seu sonho de ser magra para sempre, dê adeus as restrições alimentares e estabeleça uma relação curada com a comida a nível emocional e espiritual.",
    // SubHeadline: [
    //     "Aprenda de uma vez por todas a eliminar o efeito sanfona, dar adeus às dietas restritivas e se tornar uma mulher magra de corpo e alma."
    // ],
    borderTimeStyle: "#C5C451 solid 2px",
    pageDate: "De 9 a 12 de agosto | 100% online e gratuito",
    colorButton: "linear-gradient(to bottom, #C5C451, #C5C451)",
    textButton: "Quero trilhar essa jornada!",
    colorTextButton: "#710A65",
    secondHead: "O que você vai dominar nesse curso:",
    secondHeadItemsCardColor: "white",
    secondHeadItemsTextColor: "#710A65",
    secondHeadItems: [
        {
            img: a1,
            text: "Os principais sabotadores da sua dieta e como vencê-los"
        },
        {
            img: a2,
            text: "O real motivo pelo qual você não consegue emagrecer"
        },
        {
            img: a3,
            text: "Como criar um estilo de vida saudável, de forma prática e sem te roubar tempo"
        },
        {
            img: a4,
            text: "A Raiz do seu descontrole alimentar"
        },
        {
            img: a5,
            text: "Desinstale os bloqueios emocionais que travam o seu emagrecimento"
        },
        {
            img: a6,
            text: "Aprenda como dominar as suas emoções de ansiedade, gula e compulsão"
        },
        {
            img: a7,
            text: "Aprenda técnicas para aumentar seu metabolismo e emagrecer dormindo!"
        },
        {
            img: a8,
            text: "Livre-se das dietas, e aprenda a emagrecer de forma mais leve e duradoura!"
        },
        {
            img: a9,
            text: "Aprenda como perpetuar o seu emagrecimento sem esforços e de forma consistente"
        },
    ],
    specialSectionText: "Você está à um passo de despertar suas habilidades emocionais para viver livre financeiramente, bem-sucedida profissionalmente e forte para enfrentar os seus desafios.",
    expertDescription: [
        "Dra. Kárita Lima, nutricionista, terapeuta ayurvédica, psico. transpessoal, doula, e health coach, atua na área da saúde e alimentação desde 2007 e já ajudou mais de 3000 mulheres a desenvolverem um relacionamento saudável com a comida e com o seu corpo, encontrando a paz e a alegria de viver."
    ]
}

export default function Captura() {
    
    return (
        <>
            <section className="captura__background mh_65 d-flex flex-column justify-content-center align-items-center px-4 py-5 text-center" style={{ background: pageData.mainBackgroundColor, backgroundSize: "cover", backgroundPosition: "center" }}>

                <img className="img-fluid mb-2" src={logo} alt="imagem" />

                <div className="col-lg-8 d-flex flex-column justify-content-center align-items-center ">

                    <p className=" white font32 wg7 mt-3">
                        {pageData.Headline}
                    </p>

                    <p className="jost white wg5 font18">
                        {pageData.SubHeadline}
                    </p>

                    <form method="POST" action="https://coalma.activehosted.com/proc.php" id="_form_29_" className="col-lg-6" novalidate>

                    <input type="hidden" name="u" value="29" />
                    <input type="hidden" name="f" value="29" />
                    <input type="hidden" name="s" />
                    <input type="hidden" name="c" value="0" />
                    <input type="hidden" name="m" value="0" />
                    <input type="hidden" name="act" value="sub" />
                    <input type="hidden" name="v" value="2" />

                        <div className="badge__time px-2 font18 py-1 w-100" style={{ border: pageData.borderTimeStyle }}>
                            <p className="jost white badge__time__text">
                                {pageData.pageDate}
                            </p>
                        </div>

                        <input className="form-control mt-4 input__mod" id="fullname" name="fullname" placeholder="Seu Nome" type="text" required />
                        <input className="form-control mt-2 input__mod" id="email" name="email" placeholder="Seu Melhor E-Mail" type="text" required />

                        <button id="_form_5_submit" class="_submit" type="submit" className="btn btn-danger btn__cta mt-4 w-100 px-5" style={{ background: pageData.colorButton, color: pageData.colorTextButton }}>
                            {pageData.textButton}
                        </button>
                    </form>

                </div>

            </section>













            <section className="bg2x bg-light d-flex flex-column justify-content-center align-items-center px-4 py-5 text-center">

                <p className="font32 wg7" style={{ color: pageData.mainColor }}>
                    {pageData.secondHead}
                </p>

                <img className="img-fluid mb-5 mt-3" src={separator} alt="imagem" />

                <div className="col-lg-10 d-flex flex-row flex-wrap justify-content-center align-items-center">

                    {
                        pageData.secondHeadItems.map((element, index) => {
                            return (
                                <div className="col-lg-4 px-2 mb-3">

                                    <div className="eem__card1 px-4 d-flex flex-column justify-content-center align-items-center shadow" style={{ backgroundColor: pageData.secondHeadItemsCardColor }}>

                                        {/* <img className="img-fluid mt-4 pt-3" src={element.img} alt="imagem" /> */}

                                        <p className="wg6 m-0" style={{ color: pageData.secondHeadItemsTextColor }}>
                                            {element.text}
                                        </p>

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

            </section>





























            <section className="bg1 d-flex flex-column justify-content-center align-items-center px-4 py-5 text-center" style={{ background: "linear-gradient(105.36deg, #710A65 0%, #28033F 100%)" }}>

                <div className="col-lg-10 d-flex flex-row flex-wrap justify-content-center align-items-center">

                    {
                        icons.map((element, index) => {
                            return (
                                <div className="col-lg-3 px-2 my-3">

                                    <div className="eem__card2">

                                        <img className="img-fluid" src={element.img} alt="imagem" />

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

            </section>



            <section className="bg2x bg-light d-flex flex-column justify-content-center align-items-center px-4 py-5 text-center">

            {/* </section> */}










            












            {/* <section className="bg2x bg-light d-flex flex-column justify-content-center align-items-center px-4 py-5 text-center"> */}
                <p className="font32 wg7 " style={{ color: pageData.mainColor }}>
                    Cronograma
                </p>

                <img className="img-fluid mb-5" src={separator} alt="imagem" />

                <div className="col-lg-9 d-flex flex-row flex-wrap justify-content-center align-items-center mb-5">

                    {
                        icons2.map((element, index) => {
                            return (
                                <div className="col-lg-5 px-2 mb-5">

                                    <div className="eem__card2">

                                        <img className="img-fluid" src={element.img} alt="imagem" />

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

                {/* <p className="c1 bebas font64 mt-5">
                    depoimentos
                </p>

                <img className="img-fluid mb-5" src={separator} alt="imagem" />

                <div className="col-lg-8 d-flex flex-row flex-wrap justify-content-center align-items-center">

                    {
                        icons2.map((element, index) => {
                            return (
                                <div className="col-lg-6 px-2 mb-4">

                                    <div className="eem__card2">

                                        <img className="img-fluid" src={video} alt="imagem" />

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>  */}

                <div className="col-lg-10 d-flex flex-row flex-wrap justify-content-center align-items-center py-5">

                    <div className="col-lg-5 px-2">

                        <img className="img-fluid" src={giu2} alt="imagem" />

                    </div>

                    <div className="col-lg-5 px-2 text-start quemsoueu__text">

                        <p className="font32 wg7 mt-5" style={{ color: pageData.mainColor }}>
                            Quem Sou Eu
                        </p>

                        <img className="img-fluid mb-4" src={separator} alt="imagem" />

                        {
                            pageData.expertDescription.map((element, index) => {
                                return (
                                    <p className="font16 badge__time__text2">
                                        {element}
                                    </p>
                                )
                            })
                        }

                    </div>

                </div>


            </section>

















            <section className="captura__background d-flex flex-column justify-content-center align-items-center px-4 py-5 text-center" style={{ background: pageData.mainBackgroundColor, backgroundSize: "cover", backgroundPosition: "center" }}>

                {/* <img className="img-fluid" src={logo} alt="imagem" /> */}

                <p className=" white font32 wg7">
                    Inscreva-se Gratuitamente
                </p>
                <form method="POST" action="https://coalma.activehosted.com/proc.php" id="_form_29_" className="col-lg-4" className="col-lg-4" novalidate>

                <input type="hidden" name="u" value="29" />
                <input type="hidden" name="f" value="29" />
                <input type="hidden" name="s" />
                <input type="hidden" name="c" value="0" />
                <input type="hidden" name="m" value="0" />
                <input type="hidden" name="act" value="sub" />
                <input type="hidden" name="v" value="2" />

                    <div className="badge__time px-2 font18 py-1 w-100" style={{ border: pageData.borderTimeStyle }}>
                        <p className="jost white badge__time__text">
                            {pageData.pageDate}
                        </p>
                    </div>

                    <input className="form-control mt-4 input__mod" id="fullname" name="fullname" placeholder="Seu Nome" type="text" required />
                    <input className="form-control mt-2 input__mod" id="email" name="email" placeholder="Seu Melhor E-Mail" type="text" required />

                    <button id="_form_5_submit" class="_submit" type="submit" className="btn btn-danger btn__cta mt-4 w-100 px-5" style={{ background: pageData.colorButton, color: pageData.colorTextButton }}>
                        {pageData.textButton}
                    </button>
                </form>

            </section>
        </>
    )
}

const icons = [
    {
        img: b1,
    },
    {
        img: b2,
    },
    {
        img: b3,
    },
    {
        img: b4,
    }
]

const icons2 = [
    {
        img: c1,
    },
    {
        img: c2,
    },
    {
        img: c3,
    },
    {
        img: c4,
    }
]